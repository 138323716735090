/* Header Section */
.header {
  text-align: center;
  display: flex;
  flex-direction: column;
}
.logo {
  display: flex;
  justify-content: center;
  align-items: center;
}
.logo-img {
  height: 600px;
  width: 800px;
  background: rgb(255 255 255 / 20%);
  border-radius: 50%;
  /* box-shadow: inset 0px 0px 20px 0px #ffffff; */
  /* box-shadow: 0 0 50px #ccc; */
  box-shadow: 0 0 120px 20px #ccc;
  margin: 100px;
}
.logo-xmas {
  height: 100%;
  width: 100%;
  padding: 50px 0;
  background: white;
}
.intro {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: auto;
  padding: 0;
  background-image: linear-gradient(
    to bottom,
    transparent 0%,
    lightgrey 50%,
    #ffffff 100%
  );
  -webkit-background-size: contain;
  -moz-background-size: contain;
  background-size: cover;
  position: relative;
  top: -70px;
  margin-bottom: -70px;
}

.intro p {
  font-family: "Raleway", sans-serif;
  color: black;
  font-size: 22px;
  font-weight: 500;
  line-height: 30px;
  margin: 20px;
  padding-block: 20px;
}

/* About Section */
.about {
  padding: 100px 0;
  color: black;
  background: rgb(255 255 255 / 40%);
}
.about h3 {
  font-size: 22px;
  margin: 0 0 20px;
}
.about h2 {
  position: relative;
  margin-bottom: 15px;
  padding-bottom: 15px;
}
.about h2::after {
  position: absolute;
  content: "";
  /* background: linear-gradient(to right, #fbd7c1 0%, #f27224 100%); */
  /* XMAS */
  background: linear-gradient(to right, #fbd7c1 0%, red 100%);
  height: 4px;
  width: 183px;
  bottom: 0;
  left: 0;
}
.about-text {
  padding: 0 15px;
}
.about img {
  background: #fff;
  border-right: 0;
  box-shadow: 0px 0px 30px #ccc;
  margin: 5px;
}
.about p {
  line-height: 24px;
  font-size: 18px;
  margin: 30px 0;
}
/* Gallery Section */
.gallery {
  padding: 100px 0;
  background-color: white;
  color: black;
}

.gallery-items {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
}

.gallery .section-title {
  margin-bottom: 30px;
}

.gallery .section-title h2::after {
  width: 162px;
  bottom: 0;
  margin-left: -80px;
}

.gallery .cta {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  align-content: center;
  margin-top: 30px;
}

.gallery .cta h3 {
  margin: 0;
}

.gallery .cta a {
  border: none;
}

.gallery .etsy-logo {
  width: 50px;
}

.gallery .sub-title {
  margin: 0;
  margin-top: 40px;
  padding: 0;
  display: block;
  text-align: left;
}

.gallery-item {
  margin: 10px -5px;
  min-width: 200px;
  max-width: 390px;
  padding: 0;
  box-shadow: 3px 6px 20px 2px lightgrey;
}
.gallery-item .hover-bg {
  overflow: hidden;
  position: relative;
  margin: 0;
}
.hover-bg .hover-text {
  position: absolute;
  text-align: center;
  margin: 0 auto;
  color: #fff;
  background: linear-gradient(to top, #f2722447 0%, #000000 100%);
  padding: 20% 0 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: all 0.5s;
}
.hover-bg .hover-text > h4 {
  opacity: 0;
  color: #fff;
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
  transition: all 0.3s;
  font-size: 28px;
  letter-spacing: 0.5px;
  font-weight: 600;
  text-transform: uppercase;
}
.hover-bg:hover .hover-text > h4 {
  opacity: 1;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}
.hover-bg:hover .hover-text {
  opacity: 0.7;
}
/* Contact Section */
.contact {
  padding: 50px 0;
  background: linear-gradient(
    to right,
    #f5ede9 0%,
    #ffffff 20%,
    #ffffff 80%,
    #f5ede9 100%
  );
  color: black;
}

.contact .section-title p {
  font-size: 16px;
}
.contact h2 {
  color: black;
  margin-top: 10px;
  margin-bottom: 15px;
  padding-bottom: 15px;
}

.contact .contact-item i.fa {
  margin-right: 10px;
}

.contact .social {
  border-top: 1px solid rgba(255, 255, 255, 0.15);
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  padding: 50px 0;
  text-align: center;
}

.contact .social .follow-us {
  padding-bottom: 24px;
  display: flex;
  text-align: center;
  flex-direction: column;
}

.contact .social ul li {
  display: inline-block;
  margin: 20px;
}
.contact .social i.fa {
  font-size: 22px;
  width: 48px;
  height: 48px;
  padding: 12px 0;
  border: 2px solid #000;
  color: #000;
  border-radius: 50%;
  transition: all 0.3s;
}
.contact .social i.fa:hover {
  background: #fff;
}

i.fa:hover.fa-facebook {
  color: #1778f2;
  border: 2px solid #1778f2;
}

i.fa:hover.fa-instagram {
  color: #fa8225;
  border: 2px solid #fa8225;
}

i.fa:hover.fa-pinterest {
  color: #f0002a;
  border: 2px solid #f0002a;
}

i.fa:hover.fa-shopping-cart {
  color: #f56400;
  border: 2px solid #f56400;
}

/* Footer Section*/
.footer {
  background-color: black;
  padding: 10px 0;
  position: relative;
}

.footer .container {
  display: flex;
  flex-direction: column-reverse;
}

.footer a {
  font-family: "Lato", sans-serif;
  text-transform: uppercase;
  color: white;
  font-size: 15px;
  font-weight: 400;
  padding: 8px 2px;
  border-radius: 0;
  margin: 9px 20px 0;
}

.footer a:hover {
  color: white;
  text-decoration: none;
}

.footer a:hover:after {
  width: 100%;
}

.footer a:after {
  display: block;
  position: relative;
  left: 0;
  bottom: -1px;
  width: 0;
  height: 2px;
  background-image: linear-gradient(
    to right,
    #008d36 0%,
    #ffffff 33%,
    #ffffff 66%,
    #e30613 100%
  );
  /* background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%); */
  content: "";
  transition: width 0.2s;
}

@media screen and (max-width: 600px) {
  .logo-img {
    width: 100%;
    border-radius: 0%;
  }
  .about h2 {
    margin-top: 15px;
  }
}

@media screen and (min-width: 601px) and (max-width: 900px) {
  .about h2 {
    margin-top: 15px;
  }
}
